/// <reference path="../../node_modules/@aspnet/signalr/dist/esm/browser-index.d.ts" /> 
/// <reference path="../../node_modules/@types/moment/index.d.ts" /> 
///// <reference path="../../node_modules/moment/moment.d.ts" />

$.when($.ready).done(() => {
	if (!$('.table-torrent').length) {
		return;
	}

	const debugHub = new signalR.HubConnectionBuilder()
		.withUrl('/debug')
		.configureLogging(signalR.LogLevel.Information)
		.build();
	debugHub.serverTimeoutInMilliseconds = 70000;
	debugHub.start().catch(err => console.error(err.toString()));

	// restart connection on close
	const startSignalRConnection = connection => connection.start()
		.then(() => console.info('Websocket Connection Established'))
		.catch(err => console.error('SignalR Connection Error: ', err));
	debugHub.onclose(() => setTimeout(startSignalRConnection(debugHub), 5000));

	function writeLog(msg: string) {
		$('#console').append(`<p>${moment.utc().format()}: ${msg}<br/></p>`);
		
	}

	debugHub.on('writeLog', writeLog);
});
