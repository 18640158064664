/// <reference path="../../node_modules/@types/jquery/index.d.ts" /> 
/// <reference path="../../node_modules/@types/jquery.form/index.d.ts" />
/// <reference path="../lib/noty/index.d.ts" />

interface IDropJQueryEventObject extends Event {
	dropEffect: string;
	effectAllowed: ConstrainDOMString;
	dataTransfer: IDataTransferObject;
}

interface IDataTransferObject {
	files: string[];
}

$.when($.ready).done(() => {
	// toggle the input (url / file upload) in the upload form
	$('.form-upload-torrent').on('click', 'button.btn-switch-upload-file', function (e) {
		e.preventDefault();

		const form = $(this).closest('form');
		const buttons = form.find('button.btn-switch-upload-file');
		const inputs = form.find('input');

		buttons.toggleClass('active');
		buttons.filter('.active').prop('disabled', true);
		buttons.filter(':not(.active)').prop('disabled', false);
		inputs.toggleClass('hidden');

		// disable the submit button if the visible input is empty
		$('.form-upload-torrent-submit').prop('disabled', $('.form-upload-torrent input:visible').val() === '0');
	});

	// disable the submit button if the visible input is empty
	$('.form-upload-torrent input[type="file"]').on('change', function () {
		const form = $(this).closest('form');
		const inputMe = this as HTMLInputElement;

		form.find('.form-upload-torrent-submit').prop('disabled', inputMe.value === '0');
		//form.find('input[type="text"]').attr('disabled', true);

		if (inputMe.value) {
			form.prop('action', '/Home/AddTorrentFile');
		}
	});

	$('.form-upload-torrent input[type="text"]').on('input propertychange', function () {
		const form = $(this).closest('form');
		const inputMe = this as HTMLInputElement;

		form.find('.form-upload-torrent-submit').prop('disabled', inputMe.value === '0');
		//form.find('input[type="file"]').attr('disabled', true);

		if (inputMe.value) {
			if (/^magnet:/.test(inputMe.value)) {
				form.prop('action', '/Home/AddTorrentMagnet');
			} else if (/^http/.test(inputMe.value)) {
				form.prop('action', '/Home/AddTorrentLink');
			}
		}
	});

	var ajaxSuccess = data => {
		//$('.form-upload-torrent')[0].reset();
		if (data.success) {
			new Noty({
				text: 'Un nouveau torrent a été ajouté avec succès',
				type: 'success'
			}).show();

			// TODO add torrent line if on the right page
			const torrentTable = $('.table-torrent tbody tr:first');
			if (torrentTable.length) {
				torrentTable.before(data.torrentLine);
			}
		} else {
			new Noty({
				text: data.message,
				type: 'error'
			}).show();
		}
	};

	var ajaxOptions = {
		beforeSubmit: (arr, $form) => {
			const urlInput = $form.find('[type="text"]')[0];
			const fileInput = $form.find('[type="file"]')[0];

			if (!urlInput.value && !fileInput.value) {
				new Noty({
					text: 'Veuillez spécifier une url ou un fichier à charger.',
					type: 'warning'
				}).show();
				return false;
			}

			return true;
		},
		resetForm: true,
		success: ajaxSuccess
	};

	// drag & drop a torrent file
	//$.event.props.push('dataTransfer');
	$('.form-upload-torrent button, .form-upload-torrent input').bind('drop', e => {
		e.preventDefault();

		const files = (e.originalEvent as IDropJQueryEventObject).dataTransfer.files;

		$.each(files, (index, file) => {
			// validation tests

			const formData = new FormData();
			formData.append('torrent', file);

			$.ajax({
				url: '/Home/AddTorrentFile',
				data: formData as JQuery.PlainObject,
				processData: false,
				contentType: false,
				type: 'POST',
				success: ajaxSuccess
			});
		});

		return false;
	});

	// upload form submit in ajax
	$('.form-upload-torrent').submit(function () {
		$(this).ajaxSubmit(ajaxOptions);

		return false;
	});

	// refresh the consumed storage each minute
	var refresh = () => {
		$.get('/Home/GetFreeSpace', data => {
			$('#storage-consumption').replaceWith(data);
		});
	};
	window.setInterval(refresh, 60 * 1000);
	refresh();

	$('#console-button').on('click',
		() => {
			$('#console').toggle();
			if ($('#console:visible')) {
				$('body').css('padding-bottom', 100);
			} else {
				$('body').css('padding-bottom', 0);
			}
		});
});