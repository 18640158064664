/// <reference path="../../node_modules/@types/jquery/index.d.ts" /> 
/// <reference path="../lib/noty/index.d.ts" />

Noty.overrideDefaults({
	layout: 'bottomRight',
	theme: 'bootstrap-v3',
	timeout: 10 * 1000
});

$.when($.ready).done(() => {
	var sorting = 'CreationDate';
	var sortDesc = true;
	var refreshTimer = null;

	// === BEGIN fallback to login page ===
	$.ajaxSettings.success = (data: JQuery.PlainObject<string>, result, request) => {
		var authUrl = request.getResponseHeader('X-Authenticated');

		if (refreshTimer !== null && !$('#mtoolbar').length) {
			window.clearInterval(refreshTimer);
		}

		if (authUrl) {
			window.location.assign(authUrl);
			return;
		}

		var noAjax = request.getResponseHeader('X-No-Ajax') === '1';
		if (noAjax) {
			var newDoc = document.open('text/html', 'replace');
			newDoc.write(new String(data) as string);
			newDoc.close();
		}
	};

	$.ajaxSettings.error = (jqXhr) => {
		if ($('nav.navbar').length && jqXhr.status === 401) {
			location.reload(true);
		}
	};
	// === END fallback to login page ===

	// === BEGIN subpanels ===
	$('.table-torrent').on('click', 'a.js-view-trackers, a.js-view-peers, a.js-view-files', function (e) {
		e.preventDefault();
		var tr = $(this).closest('tr');
		var panel = tr.next('.subpanel:first');
		var inputUrl = tr.find('input[name="url"]');
		var me = this as HTMLAnchorElement;

		if (inputUrl.val() === this.attributes.getNamedItem('href').value) {
			$(panel).remove();
			inputUrl.val('');
		} else {
			inputUrl.val(this.attributes.getNamedItem('href').value); // do not use this.href here
			$.post(me.href, data => {
				if (panel.length) {
					panel.replaceWith(data);
				} else {
					tr.after(data);
					panel = tr.next('.subpanel:first');
					panel.show();
				}
			});
		}
	});
	// === END subpanels ===

	// === BEGIN sorting ===
	$('#torrents > thead > tr > th[data-sort]').on('click', function () {
		var newSort = this.attributes['data-sort'].value;
		if (sorting !== newSort) {
			sorting = newSort;
			sortDesc = false;
		} else {
			sortDesc = !sortDesc;
		}

		refresh();
	});
	// === END sorting ===

	const bgRunning = 'hsl({0},75%,30%)';
	const maxHue = 80.0;

	$('.table-torrent').on('click', 'a.js-start-torrent, a.js-stop-torrent, a.js-pause-torrent, a.js-check-torrent', function (e) {
		e.preventDefault();

		var me = this as HTMLAnchorElement;
		var id = me.closest('tr').id;

		$.post(me.href, data => {
			new Noty({
				text: data.message,
				type: data.success ? 'success' : 'error'
			}).show();

			$(`#${id}`).toggleClass('stopped', false);
			$(`#${id}`).toggleClass('started', false);
			$(`#${id}`).toggleClass('paused', false);
			$(`#${id}`).toggleClass('hashing', false);

			if (me.classList.contains('js-start-torrent')) {
				$(`#${id}`).toggleClass('started', true);

				// setting colors
				var percent = parseInt($(`#${id}`).find('.progress-bar').attr('aria-valuenow'));
				var hueValue = (maxHue * Math.pow(percent / 100, 3)).toFixed();
				var myBgRunning = bgRunning.replace('{0}', hueValue);
				$(`#${id}`).css('background-color', myBgRunning);
			}
			else if (me.classList.contains('js-stop-torrent')) {
				$(`#${id}`).toggleClass('stopped', true);

				$(`#${id}`).css('background', '');
			}
			else if (me.classList.contains('js-pause-torrent')) {
				$(`#${id}`).toggleClass('paused', true);
			}
			else if (me.classList.contains('js-check-torrent')) {
				$(`#${id}`).toggleClass('hashing', true);
			}
		});
	});

	$('.table-torrent').on('click', 'a.js-delete-torrent', function (e) {
		e.preventDefault();
		var me = this as HTMLAnchorElement;
		var url = me.href;

		var n = new Noty({
			text: 'Do you really want to remove this torrent?',
			type: 'info',
			modal: true,
			force: true,
			buttons: [
				Noty.button('Yes', 'btn btn-error', () => {
					$.ajax({
						url: url,
						method: 'DELETE',
						success: data => {
							if (data.success) {
								$(this.closest('tr')).find('+ .subpanel').remove();
								me.closest('tr').remove();
							}
							new Noty({
								text: data.message,
								type: data.success ? 'success' : 'error'
							}).show();
						}
					});
					n.close();
				}),
				Noty.button('No', 'btn', () => {
					n.close();
				})
			]
		});

		n.show();
	});

	$('.table-torrent').on('click', 'tr a.toggle-tracker', function (e) {
		e.preventDefault();
		var me = this as HTMLAnchorElement;
		var url = me.href;

		$.post(url, function (data) {
			if (data.success) {
				$(this).closest('td').find('a').toggleClass('hidden');
			}

			new Noty({
				text: data.message,
				type: data.success ? 'success' : 'error'
			}).show();
		});
	});

	$('.table-torrent').on('click', 'tr a.refresh-tracker', function (e) {
		e.preventDefault();
		var me = this as HTMLAnchorElement;
		var url = me.href;

		$.post(url, data => {
			new Noty({
				text: data.message,
				type: data.success ? 'success' : 'error'
			}).show();
		});
	});

	var refresh_ajax = null;
	function refresh() {
		var urls = [];

		if (typeof (refresh_ajax) !== 'undefined' && refresh_ajax !== null) {
			refresh_ajax.abort();
		}

		$('.table-torrent input[name="url"]').each((i, el) => {
			var me = el as HTMLInputElement;

			if (typeof me.value !== 'undefined' && me.value !== '') { urls.push(me.value); }
		});
		var main = $('.table-torrent');

		refresh_ajax = $.post('Home/Refresh', $.param({ urls: urls, sorting: sorting, desc: sortDesc }, true), (data) => {
			main.html($(data.main).find('.table-torrent').html());

			$.each(data.sub, (key: string, value) => {
				// key = url
				var tr = main.find('a[href="' + key + '"]').closest('tr');

				tr.find(':input[name="url"]').val(key);
				tr.after(value).next('.subpanel:first').show();
			});
		});
	}
});