/// <reference path="../../node_modules/@types/jquery/index.d.ts" /> 
/// <reference path="../lib/noty/index.d.ts" />

$.when($.ready).done(() => {
	$('.btn-delete-login').on('click', function (e) {
		e.preventDefault();

		var me = $(this);
		var provider = me.data('provider');
		var key = me.data('providerkey');
		var url = '/Account/RemoveLoginProvider';

		$.post(url, { provider: provider, key: key }, data => {
			if (data.success) {
				me.closest('tr').remove();
				new Noty({
					text: 'Fournisseur supprimé avec succès',
					type: 'success'
				}).show();
			}
		});
	});
});