/// <reference path="../../node_modules/@types/jquery/index.d.ts" /> 
/// <reference path="../lib/noty/index.d.ts" />

$.when($.ready).done(() => {
	// add user functionality in ajax
	$('body').on('submit', 'form.form-add-user', function (e) {
		e.preventDefault();
		var me = $(this);

		$.post((this as HTMLFormElement).action, me.serialize(), data => {
			me.replaceWith(data);
		});
	});

	// delete user functionality in ajax
	$('body').on('click', 'button.btn-delete-user', function (e) {
		var me = $(this);
		var userId = me.data('userid');

		$.post(`/account/deactivate?userid=${userId}`, data => {
			if (data.success) {
				new Noty({
					type: 'success',
					text: 'user successfully suspended'
				}).show();
				me.toggleClass('btn-delete-user btn-danger', false);
				me.toggleClass('btn-activate-user btn-primary', true);
				me.html('<span class="glyphicon glyphicon-log-in"></span> Activer');
			} else {
				new Noty({
					type: 'error',
					text: data.message
				}).show();
			}
		});
	});

	// reactivate a previously disabled user
	$('body').on('click', 'button.btn-activate-user', function (e) {
		var me = $(this);
		var userId = me.data('userid');

		$.post(`/account/activate?userid=${userId}`, data => {
			if (data.success) {
				new Noty({
					type: 'success',
					text: 'user successfully suspended'
				}).show();
				me.toggleClass('btn-activate-user btn-primary', false);
				me.toggleClass('btn-delete-user btn-danger', true);
				me.html('<span class="glyphicon glyphicon-remove"></span> Supprimer');
			} else {
				new Noty({
					type: 'error',
					text: data.message
				}).show();
			}
		});
	});

	// send token functionality in ajax
	$('body').on('click', 'button.btn-send-token', function (e) {
		var me = $(this);
		var userId = me.data('userid');

		$.post(`/account/sendtoken?userid=${userId}`, data => {
			if (data.success) {
				new Noty({
					type: 'success',
					text: 'token successfully sent to user'
				}).show();
			} else {
				new Noty({
					type: 'error',
					text: data.message
				}).show();
			}
		});
	});
});