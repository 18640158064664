/// <reference path="../../node_modules/@aspnet/signalr/dist/esm/browser-index.d.ts" /> 

$.when($.ready).done(() => {
	if (!$('.table-torrent').length) {
		return;
	}

	const torrentHub = new signalR.HubConnectionBuilder()
		.withUrl('/torrent')
		.configureLogging(signalR.LogLevel.Information)
		.build();
	torrentHub.serverTimeoutInMilliseconds = 70000;
	torrentHub.start().catch(err => console.error(err.toString()));

	// restart connection on close
	const startSignalRConnection = connection => connection.start()
		.then(() => console.info('Websocket Connection Established'))
		.catch(err => console.error('SignalR Connection Error: ', err));
	torrentHub.onclose(() => setTimeout(startSignalRConnection(torrentHub), 5000));

	const bgRunning = 'hsl({0},75%,30%)';
	var maxHue = 80.0;

	function stopTorrent(hash) {
		console.info(`Torrent ${hash} has been stopped`);

		const tr = $(`#${hash}`);
		tr.toggleClass('started', false);
		tr.toggleClass('stopped', true);
		tr.css('background', '');
	}

	function startTorrent(hash) {
		console.info(`Torrent ${hash} has been started`);

		const tr = $(`#${hash}`);
		tr.toggleClass('stopped', false);
		tr.toggleClass('started', true);

		// setting colors
		const percent = parseInt(tr.find('.progress-bar').attr('aria-valuenow'));
		const hueValue = (maxHue * Math.pow(percent / 100, 3)).toFixed();
		const myBgRunning = bgRunning.replace('{0}', hueValue);
		tr.css('background-color', myBgRunning);
	}

	function removeTorrent(hash) {
		console.info(`Torrent ${hash} has been removed from the client.`);

		const torrentLine = $(`#${hash}`);
		torrentLine.next('tr.subpanel').remove();
		torrentLine.remove();
	}

	function newTorrent(hash, view) {
		console.info(`Torrent ${hash} has been added.`);

		const tr = $(`#${hash}`);
		if (!tr.length) {
			$('.table-torrent > tbody').prepend(view);
		} else {
			tr.replaceWith(view);
		}
	}

	function refreshGlobalStats(stats) {
		if (stats === null) {
			return;
		}

		if (stats.usedSpace) {
			$('#used-space').html(stats.usedSpace);
		}

		if (stats.totalSent) {
			$('#total-uploaded').html(stats.totalSent);
		}

		if (stats.totalReceived) {
			$('#total-downloaded').html(stats.totalReceived);
		}

		if (stats.currentDownloadRate) {
			$('#global-download-rate').html(stats.currentDownloadRate);
		}

		if (stats.currentUploadRate) {
			$('#global-upload-rate').html(stats.currentUploadRate);
		}
	}

	torrentHub.on('stopTorrent', stopTorrent);
	torrentHub.on('startTorrent', startTorrent);
	torrentHub.on('removeTorrent', removeTorrent);
	torrentHub.on('newTorrent', newTorrent);
	torrentHub.on('refreshGlobalStats', refreshGlobalStats);

	var torrentTemplate;
	$.get('/Torrent/Template', data => {
		torrentTemplate = data;
	});

	function updateTorrent(tr, data) {
		if (tr.length > 1) {
			tr.filter(':gt(0)').remove();
		}

		tr.find('.torrent-name').attr('title', data.name).text(data.name);
		tr.find('.torrent-uploaded').text(data.uploaded);
		tr.find('.torrent-downloaded').text(data.downloaded);
		tr.find('.torrent-ratio').text(data.ratio);
		tr.find('.torrent-upload-rate').text(data.uploadRate);
		tr.find('.torrent-download-rate').text(data.downloadRate);
		tr.find('.torrent-eta').text(data.eta);
		tr.find('.torrent-date').text(moment(data.creationDate).format("DD/MM/YYYY"));
		tr.find('.progress-bar')
			.css('width', data.percent + '%')
			.attr('aria-valuenow', data.percent)
			.text(data.percent + '%');

		if (data.started) {
			if (tr.find('.js-start-torrent:visible').length) {
				startTorrent(data.hash);
			}
		} else {
			stopTorrent(data.hash);
		}

		if (tr.find('.js-stop-torrent:visible').length) {
			var percent = data.percent;
			var hueValue = (maxHue * Math.pow(percent / 100, 3)).toFixed();
			var myBgRunning = bgRunning.replace("{0}", hueValue);
			tr.css('background-color', myBgRunning);
		}
	}

	torrentHub.on('refresh', (data) => {
		if (data !== null && Array.isArray(data)) {
			data.forEach(el => {
				const hash = el.hash;

				const tr = $(`#${hash}`);
				if (!tr.length) {
					console.warn(`New torrent detected through the refresh system: ${hash}`);

					torrentHub.invoke('GetTorrentDetail', hash).then(torrent => {
						if (torrent === null) {
							return;
						}

						var tr2 = $(`#${torrent.hash}`);
						if (tr2.length) {
							return;
						}

						var newTr = torrentTemplate;
						const badHash = $(newTr).attr('id');
						newTr = newTr.replace(new RegExp(badHash, 'g'), torrent.hash.toLowerCase());
						$('.table-torrent > tbody').prepend(newTr);
						tr2 = $(`#${torrent.hash}`);
						tr2.find('.torrent-owner').text(torrent.owner);
						tr2.find('.torrent-date').text(torrent.date);

						updateTorrent(tr2, torrent);
					}).catch(error => {
						console.error(error);
					});
				} else {
					updateTorrent(tr, el);
				}
			});

			$('#main tr[id=""]').remove();
		}
	});
});
